const Scale = require("tonal-scale");
const {numToNote} = require('./noteUtilities');
var flatOrSharp = 'default';


const { getRandomIntInclusive, pitchMap, generatePitch } = require('./noteUtilities');
/*eslint-disable complexity*/
// Arguments all optional
// Returns melody as array of midi codes. ex. [0, 30, 127]
function createRandomMelody({
  tonality = 'diatonic', // diatonic or chromatic.
  scaleType = 'major',
  key = 'C',
  pitchRange = '50 70', // Any range string. Space as seperators.
  midiLength = 8, // How many notes
  intervalJump = 3, // Biggest difference between notes
  order = 'up',
  startOnKey = true
} = {}) {
  // If no object passed all defaults used
  
  // Error checking
  tonality = tonality.toLowerCase();
  if (tonality !== 'diatonic' && tonality !== 'chromatic') {
    console.log('Invalid tonality passed. You are getting Diatonic');
    tonality = 'diatonic';
  }

  midiLength = Math.floor(Number(midiLength));
console.log(midiLength);
  if (!Number(midiLength) || midiLength < 1) {
    console.log('Invalid midiLength. You are getting 8 notes.');
    midiLength = 8;
  }
  let results = [];
  let notesToPlay = [];
  if(order == 'up')
    notesToPlay =  getMelodyUp(pitchRange, midiLength, tonality,key, scaleType);
  else
    notesToPlay =  getMelodyRandom(pitchRange, midiLength, tonality,key, scaleType, intervalJump, startOnKey);
  
    let pitchDisplayNames = GetDisplayNotes(notesToPlay)
    //console.log('pitchnames' + pitchDisplayNames)
  results.push({
    notesToPlay, pitchDisplayNames
   });
   flatOrSharp = 'default';

    return results;
}

// Helper Functions

function getMelodyUp(pitchRange, midiLength, tonality,key, scaleType)
{
  pitchRange = getMidiRange(pitchRange); // returns range object with min and max properties
  // End Error Checking

  let result = [];
  let display = [];
  let lastNote = pitchRange.min-1;
  let currentNote = lastNote;

  // To allow easy range checking
  function currentNoteInRange() {
    return currentNote >= pitchRange.min && currentNote <= pitchRange.max;
  }

  while (result.length < midiLength) {
    do {
      if(currentNote > pitchRange.max)
      {
         currentNote = pitchRange.min-1
      }
      else
      {
        currentNote++;
      }
      //currentNote = getRandomIntInclusive(curMin, curMax);
    } while (!currentNoteInRange() || !isInScale(currentNote, tonality, key, scaleType));
    
    
    lastNote = currentNote;
    result.push(currentNote);

  }
//console.log('Display Notes ' + GetDisplayNotes(result));
  
  return result;
}

function getMelodyRandom(pitchRange, midiLength, tonality,key, scaleType, intervalJump, startOnKey)
{
  pitchRange = getMidiRange(pitchRange); // returns range object with min and max properties
  // End Error Checking

  let result = [];
  let display = [];
  var lastNote;

  if(startOnKey)
  {
     lastNote = pitchRange.min;
    result.push(lastNote);
  }
  else
  {
    lastNote = getRandomIntInclusive(pitchRange.min, pitchRange.max); // So we can decide about repetition. Unused atm.
  }

  let currentNote = lastNote;

  // To allow easy range checking
  function currentNoteInRange() {
    return currentNote >= pitchRange.min && currentNote <= pitchRange.max;
  }
  while (result.length < midiLength) {

     let curMin =
        currentNote - intervalJump < 0 ? 0 : currentNote - intervalJump; //    0 is hard min
       let curMax =
      currentNote + intervalJump > 127 ? 127 : currentNote + intervalJump; //  127 is hard max

  //   // Roll until we get a number in range
  // // If tonality is diatonic it also rolls until its a whole note number.
     do {
       currentNote = getRandomIntInclusive(curMin, curMax);
      } while (!currentNoteInRange() || !isInScale(currentNote, tonality, key, scaleType));


     lastNote = currentNote;
     result.push(currentNote);


   }
   console.log('Notes ' + result);

  //console.log('Display Notes ' + GetDisplayNotes(result));
  
  return result;
}

function GetDisplayNotes(scale)
{
  let notes = [];
  let octaves = [];

    for (let index = 0; index < scale.length; index++) {
   let fullNote = scale[index];
   var note = numToNote(fullNote).match(/(?:(?!\d|-).)*/gi)[0].toUpperCase();
   let octave = numToNote(fullNote).toString().match(/[-]*\d/g); // Grab number from string. Potentially negative

    notes.push(note);
    octaves.push(octave);
    //numToNote(pitch).match(/(?:(?!\d|-).)*/gi)[0].toUpperCase()

    const pitchPair = {};

    if (flatOrSharp =='flat' || flatOrSharp =='flatflat')
    {
          pitchPair["C#"] = "Db";
          pitchPair["D#"] = "Eb";
          pitchPair["F#"] = "Gb";
          pitchPair["G#"] = "Ab";
          pitchPair["A#"] = "Bb";
          if (flatOrSharp =='flatflat')
          {
              pitchPair["B"] = "Cb";
              pitchPair["E"] = "Fb";
          }
          notes = notes.map(n=> {
          if(pitchPair[n]) {
              return pitchPair[n];
          } else {
              return n;
          }   
          });
    }
    else if (flatOrSharp =='sharp')
    {
        pitchPair["F"] = "E#";
        pitchPair["C"] = "B#";
        
        notes = notes.map(n=> {
        if(pitchPair[n]) {
          return pitchPair[n];
        } else {
          return n;
        }   
      
      });
    }

  }
  let fullDisplayNote = [];

  fullDisplayNote.push({
    notes, octaves
  });


    return fullDisplayNote;


}

function isInScale(note, tonality, key, scaleType) {
  if (tonality !== 'diatonic') {
    return true;
  } 
  var pitch = note;
  var note = numToNote(pitch).match(/(?:(?!\d|-).)*/gi)[0].toUpperCase();
    //console.log("scale - " + Scale.notes(key, scaleType));

  const pitchPair = {};

  pitchPair["Db"] = "C#";
  pitchPair["Eb"] = "D#";
  pitchPair["Gb"] = "F#";
  pitchPair["Ab"] = "G#";
  pitchPair["Bb"] = "A#";
  pitchPair["Cb"] = "B";
  pitchPair["Fb"] = "E";
  pitchPair["E#"] = "F";
  pitchPair["B#"] = "C";

  let notes = Scale.notes(key, scaleType);
  
  notes = notes.map(n=> {
    if(pitchPair[n]) {
      if (flatOrSharp == 'default')
      {
        if (notes.includes('E#') || notes.includes('B#'))
        flatOrSharp = 'sharp'
        else if (notes.includes('Cb') || notes.includes('Fb'))
        flatOrSharp = 'flatflat'
        else
        flatOrSharp = 'flat'
      }
      return pitchPair[n];
    } else {
         
      return n;
    }

  });

//console.log("notesinscale - " + notes);

//console.log(flatOrSharp);
console.log('note - ' + note);
//console.log('in scale ' + notes.includes(note));

return notes.includes(note);
}


// Takes string. Works out Range. Can mix and match formats and orders
// "30 40", "C0 50", "C-1 10", "G3 G5", "G3 79", "30 10", "30 40 C5" all valid
function getMidiRange(pitchRange = '') {
  if (typeof pitchRange !== 'string' || pitchRange === '') {
    return { min: 0, max: 127 }; // No (or invalid) input
  }
  let inputs = pitchRange
    .split(' ') // Split input on spaces
    .map(input => {
      // Map inputs to note numbers
      if (Number.isInteger(Number(input))) {
        // If input is a whole number
        return Number(input);
      } else {
        // Note String
        let note = input.match(/(?:(?!\d|-).)*/gi)[0].toUpperCase(); // Grab Note part. Thank you https://regex101.com
        let octave = Number(input.match(/[-]*\d/g)); // Grab number from string. Potentially negative
        if (pitchMap[note]) {
          // Passed in note exists
          return pitchMap[note] + 12 * (octave + 1); // Return note number
        } else {
          console.log('Invalid Range Value Passed - ', input);
          return 'Invalid';
        }
      }
    })
    .filter(input => input !== 'Invalid'); // Throw away any invalid input

  return { min: Math.min(...inputs), max: Math.max(...inputs) };
}



export default createRandomMelody;
