import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import History from "./helpers/history.helper";

import Main from "./components/Main";
import Recording from "./components/Recording";

export default function Routes() {
    return (
        <Router history={History}>
            <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/recording" component={Recording} />
            </Switch>
        </Router>
    );
}