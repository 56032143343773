import createRandomMelody from './createRandomMelody';

const MidiWriter = require('midi-writer-js');
const {numToNote} = require('./noteUtilities');

/*eslint-disable complexity*/
// called when 'generate' is clicked
function generateMidiArray({
  tonality = 'diatonic',
  pitchRange = '55 80',
  scaleType = 'major',
  midiLength = 1,
  intervalJump = 10,
  rhythmValues = 4,
  midiQuantity = 1,
  key = 'C',
  order = 'up',
  startOnKey = true

} = {}) {
  let results = [];
  let generatedArray = [];
  let midiCodeArray = [];

  for (let i = 0; i < midiQuantity; i++) {
    generatedArray = createRandomMelody({ tonality, pitchRange, midiLength, intervalJump, key, scaleType, order, startOnKey });
    
    midiCodeArray = generatedArray[0];
    let notesToPlay = midiCodeArray.notesToPlay
    let pitchNamesWithOctave =  midiCodeArray.pitchDisplayNames;
    let midiFile = createMidiFile(notesToPlay, rhythmValues);
    results.push({
      notesToPlay, pitchNamesWithOctave, midiFile
    });

  }

  return results;
}

function createMidiFile(midiCodeArray, rhythmValues) {
  let track = new MidiWriter.Track();
  let noteArray = [];
  for (let j = 0; j < midiCodeArray.length; j++) {
    let pitch = [midiCodeArray[j]];
    let duration = rhythmValues || 2;
    let note = new MidiWriter.NoteEvent({ pitch, duration });
    noteArray.push(note);
  }
  track.addEvent(noteArray);
  let write = new MidiWriter.Writer([track]);
  let file = write.dataUri();
  return file;
}

// let tonalityOptions = document.getElementById('tonalityOptions');
// let midiRange = document.getElementById('midiRange');
// let midiLength = document.getElementById('midiLength');
// let midiJump = document.getElementById('midiJump');
// let lengthValues = document.getElementById('lengthValues');
// let midiQTY = document.getElementById('midiQTY');
// let generate = document.getElementById('generate');
// let results = document.getElementById('results');

// generate.addEventListener('click', () => createMidi());

export { generateMidiArray }

