import React, { Component, useState } from 'react';

function NoteDisplay(props) {
    return <>
        {
            props.note ?
                <div className={`Note ${props.active == props.note.midi ? "active" : ""}`}>{props.note.name}</div> :
                <div className="Note notInScale"></div>
        }
    </>
}

export default NoteDisplay;