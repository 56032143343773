import React, { Component, useState } from 'react';

import Routes from "./Routes";

import History from "./helpers/history.helper";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = { };
	}

	componentDidMount() {
	}

	render() {
		return <Routes />
	}
}

export default App;