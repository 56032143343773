import React, { Component, useState } from 'react';
import logo, { ReactComponent } from '../logo.svg';
import '../App.css';
import '../css/bootstrap.css';
import '../css/font-awesome.css';
//import 'js/fancybox/source/jquery.fancybox.css';
import '../css/dzsparallaxer.css';
import '../css/jquery.mCustomScrollbar.css';
import '../css/style.css';
import '../css/ie10.css';
import '../css/ie11.css';
import MIDISounds from 'midi-sounds-react';
import { generateMidiArray } from '../script/generateMidiArray';
import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';
import jQuery from "jquery";
import wNumb from "wnumb";
//import { findAllByPlaceholderText } from '@testing-library/react';
import * as Tone from "tone";
//import p5 from "p5";

//import Sketch from "react-p5";


import SampleLibrary from '../js/Tonejs-Instruments';
//import AudioEnergy from '../js/AudioEnergy';
//import { bytesToBase64 } from '../js/Base64';


// import * as Ui from '@tonejs/ui';
import * as MidiConvert from 'midiconvert';
//import * as fs from 'fs';
//import WaveSurfer from 'wavesurfer.js';
import NoteDisplay from './controls/NoteDisplay';
import { Note, Interval, Distance, Scale, Chord } from "tonal";

//const Scale = require("tonal-scale");
const { numToNote } = require('../script/noteUtilities');
const synth = new Tone.Synth().toMaster();
//const { MidiConvert } = require('@tonejs/midi')
const wav = require('synth-js');
var scale = require('music-scale')

var data = [];
var noteNames = [];
var midiArray = [];
// var audioRecorder;
// var player;
// var analyser;

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedInstrument: "263",
			instrument: "guitar-acoustic",
			scale: "major",
			intervalJump: 3,
			midiLength: 10,
			minValue: "50",
			maxValue: "70",
			order: "random",
			tempo: '100',
			startOnKey: false,
			showNotes: false,
			showMajor: true,
			noteNames: [],
			active: null,
			display: [],
			playing: true,
			iteration: 0,
			playRepeat: false

		};

		this.tonePlayer = React.createRef();
		this.tonePlayToggle = React.createRef();
		//this.toneOscilloscope = React.createRef();
		//this.toneFft = React.createRef();
	}

	componentDidMount() {
		this.setState({ initialized: true });
		//this.setupPitchSlider()
		this.setupNotesSlider();
		this.setupTempoSlider();
		this.setupIntervalSlider();


		document.querySelector('#play').addEventListener('touchstart', function () {
			synth.triggerAttackRelease("C4", "0");
		});

		document.querySelector('#generate').addEventListener('touchstart', function () {
			synth.triggerAttackRelease("C4", "0");
		});

	}
	onSelectInstrument(e) {
		var list = e.target;
		let n = list.options[list.selectedIndex].getAttribute("value");
		this.setState({
			instrument: n
		});
		//this.midiSounds.cacheInstrument(n);
		//this.stopAll();
	}

	onSelectScale(e) {
		var list = e.target;
		let n = list.options[list.selectedIndex].getAttribute("value");
		this.setState({
			scale: n
		}, () => {

			var chart1 = document.getElementById('chart1');
			var chart2 = document.getElementById('chart2');
			var selectedKey = document.getElementById('SelectedKey');
			var selectedScale = document.getElementById('SelectedScale');

			console.log(e);
			if (n == 'major') {
				chart1.style.opacity = "100%";
				chart1.style.zIndex = 99;
				// chart2.style.opacity = "0%";
				chart2.style.zIndex = 98;
				selectedScale.innerHTML = "Major";
				selectedKey.innerHTML = "C";
				this.state.showMajor = true;
				this.updateNotes();
			}
			else {
				// chart1.style.opacity = "0%";
				chart1.style.zIndex = 98;
				chart2.style.opacity = "100%";
				chart2.style.zIndex = 99;
				selectedKey.innerHTML = "Am";
				selectedScale.innerHTML = "Minor";
				this.state.showMajor = false;
				this.updateNotes();

			}
		});
		//e => this.setState({ scale: e.currentTarget.value })
	}

	createSelectItems() {
		if (this.midiSounds) {
			if (!(this.items)) {
				this.items = [];
				for (let i = 0; i < this.midiSounds.player.loader.instrumentKeys().length; i++) {
					this.items.push(<option key={i} value={i}>{'' + (i + 0) + '. ' + this.midiSounds.player.loader.instrumentInfo(i).title}</option>);
				}
			}
			return this.items;
		}
	}

	setupPitchSlider() {
		var slider = document.getElementById('pitch-slider');

		noUiSlider.create(slider, {
			start: [50, 70],
			connect: true,
			behaviour: 'tap-drag',
			tooltips: true,
			step: 1,
			format: wNumb({
				decimals: 0
			}),
			range: {
				'min': 52,
				'max': 83
			}

		});

		var pitchValues = [
			document.getElementById('pitch-low'),
			document.getElementById('pitch-high')
		];
		const self = this;
		slider.noUiSlider.on('update', function (values, handle) {
			self.setState({ minValue: values[0] });
			self.setState({ maxValue: values[1] });
			pitchValues[handle].innerHTML = numToNote(values[handle]);
		});

	}

	setupNotesSlider() {
		var slider = document.getElementById('NoOfNotes-slider');

		noUiSlider.create(slider, {
			start: [8],
			connect: true,
			behaviour: 'tap-drag',
			tooltips: true,
			step: 1,
			format: wNumb({
				decimals: 0
			}),
			range: {
				'min': 1,
				'max': 8
			}

		});


		const self = this;
		slider.noUiSlider.on('update', function (values, handle) {
			self.setState({ midiLength: parseInt(values[0]) });
			//pitchValues[handle].innerHTML = numToNote(values[handle]);
		});

	}

	setupTempoSlider() {
		var slider = document.getElementById('Tempo-slider');

		noUiSlider.create(slider, {
			start: [100],
			connect: true,
			behaviour: 'tap-drag',
			tooltips: true,
			step: 1,
			format: wNumb({
				decimals: 0
			}),
			range: {
				'min': 1,
				'max': 300
			}

		});
		const self = this;
		slider.noUiSlider.on('update', function (values, handle) {
			self.setState({ tempo: values[0] });
			Tone.Transport.bpm.value = values[0];

			//pitchValues[handle].innerHTML = numToNote(values[handle]);
		});

	}

	setupIntervalSlider() {
		var slider = document.getElementById('Interval-slider');

		noUiSlider.create(slider, {
			start: [3],
			connect: true,
			behaviour: 'tap-drag',
			tooltips: true,
			step: 1,
			format: wNumb({
				decimals: 0
			}),
			range: {
				'min': 1,
				'max': 12
			}

		});
		const self = this;
		slider.noUiSlider.on('update', function (values, handle) {
			self.setState({ intervalJump: values[0] });
			//pitchValues[handle].innerHTML = numToNote(values[handle]);
		});

	}

	startSynth() {
		var tempo = this.state.tempo
		var self = this;
		Tone.Transport.stop();
		Tone.Transport.cancel();
		this.setState({iteration:0});
		//Tone.Transport.bpm.value = this.state.tempo;
		var midlength = self.state.midiLength

		var synth = SampleLibrary.load({
			instruments: this.state.instrument,
			onload: () => {

				MidiConvert.load(midiArray, function (midi) {
					// make sure you set the tempo before you schedule the events
					Tone.Transport.bpm.value = tempo

					var midiPart = new Tone.Pattern(function (time, note) {
						
						synth.triggerAttackRelease(note.name, note.duration, time, note.velocity);


						self.setState({
							active: note.midi
						})
					}, midi.tracks[0].notes);

					if(!self.state.playRepeat)
					{					
						midiPart.iterations = midlength;
					}
					midiPart.pattern = "up"					
					console.log("length" + midlength);

					midiPart.callback = (time, value) => {
						//console.log(midiPart2.state);
						var iter = self.state.iteration;
						iter++;
						self.setState({iteration: iter});
						if(!self.state.playRepeat && self.state.iteration == midlength)
						{
							self.setState({playing:false})
						}
					}

					var midiPart2 = new Tone.Pattern(function (time, note) {
						
						synth.triggerAttackRelease(note.name, note.duration, time, note.velocity);
						self.setState({
							active: note.midi
						})
					}, midi.tracks[0].notes);
					if(!self.state.playRepeat)
					{					
						midiPart2.iterations = midlength;
					}	
			
					midiPart2.pattern = "up"
					
					//console.log(midiPart2)
					midiPart.start()
					//midiPart.stop()
					midiPart2.start()

					

			
						
					Tone.Transport.start();
					console.log(Tone.Transport)
					
				});
			}
		}).toMaster();
		//this.setup();

	}

	startPlayRepeat()
	{
		this.setState({playRepeat: true})
		this.startPlay();
	}

	startPlayOnce()
	{
		this.setState({playRepeat: false})
		this.startPlay();

	}


	startPlay() {
		if (!this.state.playing) {
			this.setState({ playing: true }, () => {
				if (data.length < 1)
					this.generate();
				else {

				this.startSynth();
				}
				//this.midiSounds.startPlayLoop(data, 100, 1 / 16);
			})
		}
	}


	getKey() {
		var n = jQuery("#SelectedKey").html().replace(/\s/g, '').search('/');

		if (n < 1)
			n = 2;
		var key = jQuery("#SelectedKey").html().replace(/\s/g, '').substring(0, n).replace('♭', 'b');

		key = key.replace(/m/g, '')
		if (key == '') {
			key = 'C';
		}
		return key
	}

	getStartOctave(key) {
		if (key == 'E' || key == 'F' || key == 'F#' || key == 'Gb' || key == 'G' || key == 'G#' || key == 'Ab' || key == 'A' || key == 'A#' || key == 'Bb' || key == 'B') {
			return '3';
		}
		else if (key == 'C' || key == 'C#' || key == 'Cb' || key == 'Db' || key == 'D' || key == 'D#' || key == 'Eb') {
			return '4';
		}

	}

	getEndOctave(key) {

		if (key == 'E' || key == 'F' || key == 'F#' || key == 'Gb' || key == 'G' || key == 'G#' || key == 'Ab' || key == 'A' || key == 'A#' || key == 'Bb' || key == 'B') {
			return '4';
		}
		else if (key == 'C' || key == 'C#' || key == 'Cb' || key == 'Db' || key == 'D' || key == 'D#' || key == 'Eb') {
			return '5';
		}



	}

	updateNotes() {
		this.stopAll();
		var noteLabel = document.getElementById('generatedNotesLabel');
		noteLabel.innerHTML = '';
		this.state.active = null;
		data = [];
		var NotesInKeyLabel = document.getElementById('NotesInKey');
		var key = this.getKey();
		var startOctave = this.getStartOctave(key);
		var endOctave = this.getEndOctave(key);

		// NotesInKeyLabel.innerHTML = Scale.notes(key, this.state.scale)

		NotesInKeyLabel.innerHTML = Scale.notes(key, this.state.scale)


		var NotesInScale = scale(this.state.scale, key + startOctave)

		const display = [];

		for (let j = 0; j < Scale.notes(key, this.state.scale).length; j++) {

			display.push({
				name: Scale.notes(key, this.state.scale)[j],
				midi: Note.midi(NotesInScale[j])// calculate actual midi value
			});
		}

		display.push(display.push({
			name: Scale.notes(key, this.state.scale)[0],
			midi: Note.midi(key + endOctave) // calculate actual midi value
		}));


		this.setState({ display });
	}


	generate() {

		this.updateNotes()
		Tone.Transport.cancel();
		var guitar = this.state.selectedInstrument;
		var pitch = "60";
		data = [];
		var displaystr = '';
		var key = this.getKey();

		var maxValue = Note.midi(key + this.getStartOctave(key)) + 12
		var minValue = Note.midi(key + this.getStartOctave(key))

		let MidiArray = generateMidiArray({
			key,
			scaleType: this.state.scale,
			intervalJump: parseInt(this.state.intervalJump, 0),
			midiLength: this.state.midiLength,
			//pitchRange: this.state.minValue + ' ' + this.state.maxValue,			
			pitchRange: minValue + ' ' + maxValue,
			order: this.state.order,
			startOnKey: this.state.startOnKey
		})
		let notesToPlay = MidiArray[0].notesToPlay;
		let displayNotes = MidiArray[0].pitchNamesWithOctave[0].notes;
		let displayOctaves = MidiArray[0].pitchNamesWithOctave[0].octaves;
		noteNames = [];
		for (let j = 0; j < notesToPlay.length; j++) {
			let pitch = [notesToPlay[j]];
			data.push([[], [[guitar, [notesToPlay[j]], 16 / 16, 2]]]);
			data.push([[], []]);
			data.push([[], []]);
			data.push([[], []]);

			if (j == notesToPlay.length - 1)
				displaystr = displaystr + displayNotes[j] + displayOctaves[j] + ' ';
			else
				displaystr = displaystr + displayNotes[j] + displayOctaves[j] + ' | ';
			noteNames.push(displayNotes[j] + displayOctaves[j]);
			//this.setState({ noteNames });
		}

		var noteLabel = document.getElementById('generatedNotesLabel');
		if (noteLabel != null) {
			noteLabel.innerText = displaystr;
		}
		console.log('some notes ' + noteNames)
		midiArray = MidiArray[0].midiFile;
		this.setState({playing:false})
		this.startPlay();
		//this.startSynth();
		//	this.midiSounds.startPlayLoop(data, 100, 1 / 16);

		//this.midiConvert(MidiArray[0].midiFile);



	}
	stopAll() {
		//this.midiSounds.stopPlayLoop();
		Tone.Transport.stop();
		Tone.Transport.cancel();
		this.setState({playing: false});

	}

	// On window resize, update the canvas size
	windowResized(test) {
		this.resizeCanvas(this.windowWidth, this.windowHeight);
	}


	showNotesClicked(e) {
		this.setState({ showNotes: e.currentTarget.checked })
		console.log(data);
		if (data.length < 1) {
			this.updateNotes();
			this.setState({playing:true})
		}


	}

	render() {
		return (
			<div className="App invert-elements">
				<div className="container-fluid position-right" id="wrapper">

					<div className="background">
						<div className="layer green" ></div>
					</div>

					<div className="home" id="home"></div>

					<div className="row text-center" id="block-pricing">
						<div className="col-xs-12"><h1 className="titile-divider green"><span>Ear Trainer</span></h1></div>
						<div className="clear"></div>
						<div className="col-sm-6">
							
							<div className="cont-pricing red">
								<div className="outerpop"><div className="lollipop red"><i className="fa fa-music"></i></div></div>
								
								<a href="#" className="sorting" onClick="return false;">Scale &amp; Key</a>

								<ul>
									<li><label>Select Instrument</label>
										<select value={this.state.instrument}
											onChange={this.onSelectInstrument.bind(this)}>
											<option value="guitar-acoustic">Acoustic Guitar</option>
											<option value="guitar-electric">Electric Guitar</option>
											<option value="piano">Piano</option>

										</select>

									</li>
									<li><label>Select Scale</label>
										<select onChange={this.onSelectScale.bind(this)} >
											<option value="major">Major</option>
											<option value="minor">Minor</option>
										</select>
									</li>
									<li>
										<h5 id="SelectedKeyLabel">Select Key</h5>

										<div className="chart-container">
											<div className="row chart-pie">
												<label id="SelectedKey">C</label>
												<br></br>
												<label id="SelectedScale">Major</label>
												<div id="chart1" className="circle-of-fifths" className="jqplot-target"></div>
											</div>
											<div className="row chart-pie">
												<div id="chart2" className="circle-of-fifths" className="jqplot-target"></div>
											</div>
										</div>
									</li>
									<li>
										<h5>Notes in Key:</h5>
										<h4 id="NotesInKey">C,D,E,F,G,A,B</h4>
									</li>
									{/* <li><label>Select Pitch Range</label>
										<div class="pitch-slider-container">
											<div id="pitch-slider"></div>
										</div>
										<label>Pitch Low: </label><span id="pitch-low">
										</span>
										<br></br>
										<label>Pitch High: </label><span id="pitch-high">
										</span>
									</li> */}
								</ul>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="cont-pricing blue">
								<div className="lollipop blue"><i className="fa fa-music"></i> </div>
								<a href="#" className="sorting" onClick="return false;">Settings</a>
								<ul>


									<li><label>Max Interval between notes</label>
										<div id="Interval-slider"></div>

										{/* <input type="text" placeholder="1 - 127" onChange={e => this.setState({ intervalJump: e.currentTarget.value })}></input> */}
									</li>
									<li><label>Number of Notes</label>
										{/* <input type="text" placeholder="1 - 50" onChange={e => this.setState({ midiLength: e.currentTarget.value })}>
										</input> */}
										<div id="NoOfNotes-slider"></div>
									</li>
									{/* <li><label>Number of Melodies (Between 1 and 50)</label><input type="text"></input></li> */}
									<li><label>Scale Pattern</label>
										<select onChange={e => this.setState({ order: e.currentTarget.value })}>
											<option value="random">Random</option>
											<option value="up">Up</option>
										</select>
									</li>
									<li><label className="slider-label">Start On Key </label>
										<label className="switch">
											<input type="checkbox" onChange={e => this.setState({ startOnKey: e.currentTarget.checked })} /><span class="slider round"></span>
										</label>
									</li>

									<li><label className="slider-label">Show Note Names </label>
										<label className="switch">
											<input type="checkbox" onChange={e => this.showNotesClicked(e)} /><span className="slider round"></span>
										</label>
									</li>

									{/* <li>
										<a className="join-now btn-default" onClick={this.startPlay.bind(this)} ><i className="fa fa-play"></i>  Play</a>
										<a className="join-now btn-default" onClick={this.stopAll.bind(this)} ><i className="fa fa-stop"></i>  Stop</a>

									</li> */}
								</ul>
							</div>
						</div>
						<br /><br></br>
						<div className="col-sm-6 playbox">
							<div className="cont-pricing blue ">
								{/* <div className="lollipop blue"><i className="fa fa-play"></i> </div> */}
								{/* <a href="#" className="sorting" onclick="return false;">Play</a> */}
								<ul>
									<li>
										<a id="generate" className="join-now btn-default" onClick={this.generate.bind(this)} >  Generate</a>

									</li>





									<li className={this.state.showNotes ? "show" : "hidden"}>
										<div id=''>
											<label id='generatedNotesLabel'></label>
										</div>
									</li>
									<li className={this.state.showNotes && this.state.display.length > 0 ? "show" : "hidden"}>
										{
											this.state.showMajor ?
												<>	<div className="noteDisplay major">
													<tone-example>
														<tone-content>
															<div id="NotesMajor" className="notes">
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[6]} />
																<NoteDisplay active={this.state.active} note={this.state.display[7]} />
																<NoteDisplay />
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[3]} />
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[4]} />
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[5]} />
																<NoteDisplay active={this.state.active} note={this.state.display[0]} />
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[1]} />
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[2]} />
															</div>
														</tone-content>
													</tone-example>
												</div></> :
												<><div className="noteDisplay minor">
													<tone-example>
														<tone-content>
															<div id="NotesMinor" className="notes">
																<NoteDisplay active={this.state.active} note={this.state.display[6]} />
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[7]} />
																<NoteDisplay />
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[3]} />
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[4]} />
																<NoteDisplay active={this.state.active} note={this.state.display[5]} />
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[0]} />
																<NoteDisplay />
																<NoteDisplay active={this.state.active} note={this.state.display[1]} />
																<NoteDisplay active={this.state.active} note={this.state.display[2]} />
																<NoteDisplay />
															</div>
														</tone-content>
													</tone-example>
												</div></>
										}




									</li>

									{/* <li>
										<div className="clear"></div>
										<div id="waveform"></div>
									</li>  */}
									<li><label>Change Tempo</label>
										<div id="Tempo-slider"></div>
									</li>
									<li>
										<button id='updateNotesTrigger' className="hidden" onClick={e => this.updateNotes()} > Test</button>
									</li>

									<li>
										
										<a id='play' onClick={this.startPlayOnce.bind(this)} className={`${this.state.playing == true ? "hidden" : "center-block"} join-now btn-default`}><i className="fa fa-play"></i>  Play Once</a>
										<a id='playRepeat' onClick={this.startPlayRepeat.bind(this)} className={`${this.state.playing == true ? "hidden" : "center-block"} join-now btn-default`}><i className="fa fa-play"></i>  Play Repeat</a>

										<a className={`${this.state.active == null || this.state.playing == false ? "hidden" : "center-block"} join-now btn-default`} onClick={this.stopAll.bind(this)} ><i className="fa fa-stop"></i>  Stop</a>

									</li>
								</ul>
							</div>
						</div>
						<div className="clearfix"></div>
					</div>

				</div>

				<MIDISounds ref={(ref) => (this.midiSounds = ref)} appElementName="root" instruments={[this.state.selectedInstrument]} />
				<hr />
			</div>
		);
	}

}

export default Main;